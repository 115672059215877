.SaasTool {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.SaasToolParent{
  height: 100%;
  width: 83.9%;
  position: relative;
}

hr.rounded {
  border-top: 2px solid #843D96;
}