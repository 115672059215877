.RoomTile {
    height: 34.66%;
    width: 30.88%;
    box-shadow: 0px 2px 4px #00000080;
    border: 1px solid #0A2F42;
    margin: 0 2.08% 2.08% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.RoomTileHeader {
    height: 16.29%;
    width: 100%;
    background-color: #092B3C;
    margin: 0 0 0 0;
    font: normal normal bold 18px/21px Arial;
    color: white;
    position: relative;
}

.RoomTileImage {
    height: 83.7%;
    width: 100%;
    background-color: white;
    margin: 0 0 0 0;
}

.CloseButton {
    height: 12px;
    width: 12px;
    margin: 0 4.12% 0 0;
}