.NavigationItem {
    height: 22px;
    /* width: 140px; */
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0 0 22.9px 0;
}

.NavigationItem img {
    margin: 5px 5px 5px 5px;
    width: 20px;
    height: 20px;
}

.NavigationItem p {
    width: 100%;
    align-self: center;
    color: #ffffff;
    font-size: 1rem;
    margin: 0;
    cursor: pointer;
}