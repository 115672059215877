.ProfileActions {
    height: 91px;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    /* margin: 0 0.5% 0 1%; */
}

.OtherActions {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    flex-direction: row;
    margin : 0 0 16px 0;
}

input[type='radio'] {
    /* -webkit-appearance: none; */
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
    /* box-shadow:0 0 5px 0px gray inset; */
}

input[type='radio']:hover {
    box-shadow: 0 0 5px 0px orange inset;
}

input[type='radio']:before {
    /* content: ''; */
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

input[type='radio']:checked:before {
    background: #165B83;
}

input[type='radio']:disabled {
    background: #c0bfbf;
}