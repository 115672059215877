.NavigationItems {
  margin: 0;
  margin: 26px 0 0 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 89%;
  width: 100%;
}

.NavigationItemsContainer{
  width: 140px;
  height: 100%;
}