.EditButton {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px 0 0 6px;
}

.EditButton:disabled {
    color: #ccc;
    cursor: not-allowed;
}