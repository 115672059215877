.ScheduleMeeting {
    height: 88.57%;
    width: 81.25%;
    margin: 5.47% 9.36% 4% 9.36%;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
}

.ScheduleMeetingHeadingText {
    height: 3.48%;
    width: fit-content;
    text-align: left;
    font: normal normal bold 16px/18px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0 0 6.25% 0;
}

.InputElement {
    height: 13.15%;
    width: 100%;
}

.DateParentInput {
    height: 17.86%;
    width: 100%;
}

.SaveButton {
    height: 6.56%;
    width: 15.39%;
    background-color: #843D96;
}

.TextStyle {
    height: 25%;
    width: fit-content;
    margin: 0 0 0.96% 0;
    font: normal normal 14px/16px Arial;
}

.InputStyle {
    height: 50%;
    width: 100%;
    font: normal normal 14px/16px Arial;
    box-sizing: border-box;
    margin: 0 0 2.89% 0;
    background-color: #DFF0F7;
    color: #000000;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1px 7px 1px 7px;
    border: 1px solid;
}

.Invalid {
    border: 2px solid red;
    background-color: #fda49a;
    color: #000000;
    height: 50%;
    width: 100%;
    font: normal normal 14px/16px Arial;
    box-sizing: border-box;
    margin: 0 0 2.89% 0;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1px 10px 1px 10px;
}

.InputStyleDisabled {
    height: 50%;
    width: 100%;
    font: normal normal 14px/16px Arial;
    box-sizing: border-box;
    margin: 0 0 2.89% 0;
    background-color: #DFF0F7;
    color: #000000;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1px 7px 1px 7px;
    border: 1px solid;
    background: #c2c2c2 0% 0% no-repeat padding-box;
    cursor: not-allowed;
}

.InputStyle:disabled {
    background: #c2c2c2 0% 0% no-repeat padding-box;
    cursor: not-allowed;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DFF0F7;
    opacity: 1;
    /* Firefox */
}

.DateTimeParent {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 0 0.96% 0;
}

.DateInput {
    height: 100%;
    width: 30.77%;
    margin: 0 0.96% 0 0;
    text-align: center;
}

.TimeInput {
    height: 100%;
    width: 15.39%;
    text-align: center;
}

.TimeZoneInputParent {
    height: 23.08%;
    width: 100%;
    margin: 0 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* .TimeZoneInput {
    width:min-content;
} */

.ToText {
    height: 100%;
    width: fit-content;
    margin: 0 1.44% 0 1.44%;
    display: flex;
    align-items: center;
}

.Button {
    border: none;
    cursor: pointer;
    font: inherit;
    padding: 0;
    background: #843D96 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 6.56%;
    width: 15.39%;
    color: white;
    font: normal normal bold 18px/21px Arial;
    letter-spacing: 0px;
    text-decoration: none;
    text-align: center;
    align-self: center;
    margin: 2.89% 0 0 0;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #ffffff;
}

.Danger {
    color: #944317;
}