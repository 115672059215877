.Profile {
    height: 100%;
    width: 42.9%;
}

.ProfileActionButton {
    height: 50%;
    width: 100%;
    border: 0px;
    color: black;
    font: normal normal 16px/18px Arial;
}