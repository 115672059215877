.HeaderName {
    height: 88px;
    width: 42.71%;
    display: flex;
    align-items:flex-end;
    justify-content:left;
}

.Header p {
    width: fit-content;
    height: fit-content;
    margin: 0 0 13px 13px;
    font-size: 18px;
    font: normal normal bold 20px/23px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }