.RoomCreator {
  height: 77%;
  width: 90.05%;
  margin: 2.09% 4.97% 5.59% 4.97%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RoomsListPanel {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: auto;
}

.NewRoomTile {
  height: 34.66%;
  width: 30.88%;
  background: #DFF0F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000080;
  border: 1px solid #092B3C;
  margin: 0 2.08% 2.08% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}