.Auth {
    height: 100%;
    width: 100%;
    margin: 0 0 0 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* @media only screen and (max-width: 1200px) {
    .Auth {
        width: 1200px;
    }
}

@media only screen and (max-width: 1024px) {
    .Auth {
        width: 1024px;
    }
}

@media only screen and (max-width: 768px) {
    .Auth {
        width: 768px;
    }
}

@media only screen and (max-width: 480px) {
    .Auth {
        width: 480px;
    }
} */