.Button {
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 0;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 32px;
  width: 152px;
  color: white;
  font: normal normal bold 18px/21px Arial;
  letter-spacing: 0px;
  text-decoration: none;
  text-align: center;
}

.Button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.Success {
  color: #ffffff;
}

.Danger {
  color: #944317;
}
