.Login {
  height: 37.89%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin: 2.34% 0 2.34% 0;
}

.LoginParent {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}