.Header {
  height: 10.94%;
  width: 92.15%;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  flex-direction: row;
}

.Header p {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 13px 13px;
  font-size: 18px;
  font: normal normal bold 20px/23px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.scheduleMeetingButton {
  height: 28px;
  width: 152px;
  margin: 0 0 13px 0;
  background: #843D96 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #9E9E9E;
  border-radius: 16px;
  opacity: 1;
  cursor:pointer;
  display: flex;
  justify-content: center;
}

.scheduleMeetingArea{
  height: 33.33%;
  width: 14.4%;
  margin: 0 0 1.04% 0;
}