.LoginActions {
  height: 70px;
  width: 328px;
  margin: 22px 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.OtherActions {
  height: 25px;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: flex-start;
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-decoration: none;
}