.loading-spinner {
    width: 7.5rem;
    height: 7.5rem;
    display: inline-block;
    border: 3px solid #E0E0E0;
    border-radius: 50%;
    border-top-color: #165B83;
    margin-bottom: 10rem;
    margin-top: 2rem;
    animation: 1s spin infinite ease-in-out;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}