.Input {
  height: 72px;
  width: 328px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Label {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 17px;
  width: 328px;
  font: normal normal bold 16px/18px Arial;
  margin: 0 0 5px 0;
}

.InputElement {
  height: 28px;
  width: 312px;
  background: #DFF0F7 0% 0% no-repeat padding-box;
  color: #000000;
  border: 1px solid #000000;
  opacity: 1;
  margin: 0 0 16px 0;
  padding: 0;
  font-size: 16px;
  padding: 1px 7px 1px 7px;
}

.InputElement:focus {
  outline: none;
  background-color: #DFF0F7;
}

.Invalid {
  border: 1px solid red;
  background-color: #fda49a;
}