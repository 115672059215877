.RegisterActions {
  height: 91px;
  width: 328px;
  margin: 0 0 0 0;
  display: inline-flex;
  flex-direction: row;
}

.OtherActions {
  width: 175px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
}

.Radio {
  width: "20px";
  height: "20px";
}
