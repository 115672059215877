.Logo {
  height: 5%;
  width: 100%;
  background: #0a2f42 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Logo img {
  /* top: 10px;
    left: 72px;
    width: 76px; */
  height: 20px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  /* position: fixed; */
}
