.UserCompanyName {
  height: 6%;
  width: 100%;
  background: #dff0f7 0% 0% no-repeat padding-box;
  opacity: 1;
}

.UserCompanyName p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0% 0% 0% 0%;
  font-size: 18px;
  font: normal normal bold 18px/21px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
